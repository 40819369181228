import React from 'react'
import { WIDTH_LG } from 'src/utils/breakpoints'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import { Section } from './style'

type SuperAppInterProp = {
  setOpenModal?: (state: boolean) => void;
  setDataLayer: Function;
  dataLayer: IDataLayerParams;
}

const Hero = ({ setOpenModal, dataLayer, setDataLayer }: SuperAppInterProp) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()
  const url = 'https://inter-co.onelink.me/Qyu7/st8hva1d'

  return (
    <Section
      className='py-5 d-flex'
      role='img'
      aria-label='Mulher sentada em frente ao notebook segurando um cartão de crédito Inter e um celular enquanto realiza um pagamento de uma compra online.'
    >
      <div className='container d-flex align-items-center'>
        <div className='row'>
          <div className='col-12 col-md-7 col-lg-6 offset-md-5 offset-lg-6 content px-5 py-5'>
            <h1 className='fs-28 fs-lg-40 fs-xl-56 lh-33 lh-lg-45 lh-xl-60 text-white fw-500 mb-3'>
              Tudo para deixar seu dia a dia mais fácil
            </h1>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 text-white fw-400'>
              No Super App do Inter, você aproveita compras online, gift cards, passagens e hospedagens, recargas, plano de celular e muito mais. E o melhor, economiza ganhando cashback e mais vantagens exclusivas.
            </p>
            {width < WIDTH_LG
          ? (
            <a
              href={url}
              title='Acessar Super App'
              className='btn btn--lg bg-white text-none'
              onClick={() => {
                setDataLayer(dataLayer)
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Tudo para deixar seu dia a dia mais fácil',
                  element_action: 'click button',
                  element_name: 'Acessar Super App',
                  redirect_url: url,
                })
              }}
            >
              Acessar Super App
            </a>)
          : (
            <button
              title='Acessar Super App'
              className='btn btn--lg bg-white text-none mt-md-2'
              onClick={() => {
                setOpenModal && setOpenModal(true)
                setDataLayer(dataLayer)
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Tudo para deixar seu dia a dia mais fácil',
                  element_action: 'click button',
                  element_name: 'Acessar Super App',
                })
              }}
            >
              Acessar Super App
            </button>
          )
        }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
