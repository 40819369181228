import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'
import Link from 'src/components/GatsbyLinkWrapper/index'

import { Section } from './style'

const DuoGourmet = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 bg-gray'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center justify-content-xl-between'>
          <div className='col-12 col-md-6 col-lg-5 col-xk-6 offset-lg-2 offset-xl-1 order-md-last'>
            <div className='d-flex justify-content-center justify-content-md-end mb-3 mb-md-0'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra8-marketplace/image.webp'
                altDescription='Tela de celular mostrando o benefício de 2 pratos por 1 no Duo gourmet ao lado de um sanduíche'
                arrayNumbers={[ 312, 290, 375, 533 ]}
              />
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-28 lh-md-34 lh-xl-52 text-grayscale--500 fw-500 mt-3'>Peça 2 pratos e pague só 1 nos melhores restaurantes</h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 text-grayscale--400 mt-3'>Com o Duo Gourmet você ganha o segundo prato de cortesia e ainda garante o upgrade pro cartão Inter Black.</p>
            <Link
              title='Assinar Duo Gourmet'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none mt-2 mt-md-1'
              to='https://inter-co.onelink.me/Qyu7/zl7ezh93'
              target='blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_08',
                  element_action: 'click button',
                  element_name: 'Assinar Duo Gourmet',
                  section_name: 'Peça 2 pratos e pague só 1 nos melhores restaurantes',
                  redirect_url: 'https://inter-co.onelink.me/Qyu7/zl7ezh93',
                })
              }}
            >
              Assinar Duo Gourmet
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default DuoGourmet
