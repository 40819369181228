import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'
import Link from 'src/components/GatsbyLinkWrapper/index'

import { Section } from './style'

const GiftCard = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center justify-content-xl-between'>
          <div className='col-12 col-md-6 col-lg-5'>
            <div className='d-flex justify-content-center justify-content-lg-end mb-3 mb-md-0'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra3-marketplace/image.webp'
                altDescription='Cards com marcas ifood, uber, google play, apple, xbox e amazon'
                arrayNumbers={[ 288, 302, 360, 486 ]}
              />
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-28 lh-md-34 lh-xl-52 text-grayscale--500 fw-500 mt-3'>Os melhores gift cards com a economia do cashback</h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 text-grayscale--400 mt-3'>Seja pra você ou pra presentear, garanta seu Gift Card de jogos, streamings, lojas, aplicativos e muito mais pelo Super App.</p>
            <Link
              title='Comprar Gift Card'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none mt-2 mt-md-1'
              to='https://inter-co.onelink.me/Qyu7/bshvpsjz'
              target='blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_03',
                  element_action: 'click button',
                  element_name: 'Comprar Gift Card',
                  section_name: 'Os melhores gift cards com a economia do cashback',
                  redirect_url: 'https://inter-co.onelink.me/Qyu7/bshvpsjz',
                })
              }}
            >
              Comprar Gift Card
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default GiftCard
