import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'
import Link from 'src/components/GatsbyLinkWrapper/index'

import { Section } from './style'

const GlobalShopping = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center justify-content-xl-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            <div className='d-flex justify-content-center justify-content-lg-end mb-3 mb-md-0'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra7-1440-marketplace/image.webp'
                altDescription='Mulher sorridente sentada no braço do sofá com um notebook no colo.'
                arrayNumbers={[ 303, 251, 454, 464 ]}
                className='d-lg-none d-xl-inline'
              />
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra7-1024-marketplace/image.webp'
                altDescription='Mulher sorridente sentada no braço do sofá com um notebook no colo.'
                arrayNumbers={[ 303, 251, 454, 464 ]}
                className='d-none d-lg-inline d-xl-none'
              />
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 offset-xl-2'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-28 lh-md-34 lh-xl-52 text-white fw-500 mt-3'>Shopping global com as melhores marcas internacionais</h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 text-white mt-3'>Walmart, Best Buy, Disney e muito mais. Para ganhar cashback em compras no exterior é só abrir sua Global Account e aproveitar.</p>
            <Link
              title='Abrir Conta Global'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-white mt-2 mt-md-1'
              to='https://inter-co.onelink.me/Qyu7/kdbcymgk'
              target='blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_07',
                  element_action: 'click button',
                  element_name: 'Abrir Conta Global',
                  section_name: 'Shopping global com as melhores marcas internacionais',
                  redirect_url: 'https://inter-co.onelink.me/Qyu7/kdbcymgk',
                })
              }}
            >
              Abrir Conta Global
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default GlobalShopping
