import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'
import Link from 'src/components/GatsbyLinkWrapper/index'

import { Section } from './style'

const CellPhonePlan = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center justify-content-xl-between'>
          <div className='col-12 col-md-6 col-xl-4'>
            <div className='d-flex justify-content-center justify-content-lg-end mb-3 mb-md-0'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-1024-marketplace/image.webp'
                altDescription='Homem jovem falando ao celular e segurando um copo de café'
                arrayNumbers={[ 304, 292, 454, 360 ]}
                className='d-md-none d-lg-inline d-xl-none'
              />
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-marketplace-1440/image.webp'
                altDescription='Homem jovem falando ao celular e segurando um copo de café'
                arrayNumbers={[ 304, 292, 454, 360 ]}
                className='d-none d-md-inline d-lg-none d-xl-inline'
              />
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 offset-xl-3'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-28 lh-md-34 lh-xl-52 text-grayscale--500 fw-500 mt-3'>Plano de celular a partir de R$15</h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 text-grayscale--400 mt-3'>Faça a portabilidade de número e aproveite planos sem fidelidade, com gigas de bônus, cashback e mais vantagens.</p>
            <Link
              title='Mostrar planos'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none mt-2 mt-md-1'
              to='https://inter-co.onelink.me/Qyu7/0g46venl'
              target='blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_05',
                  element_action: 'click button',
                  element_name: 'Mostrar planos',
                  section_name: 'Plano de celular a partir de R$15',
                  redirect_url: 'https://inter-co.onelink.me/Qyu7/0g46venl',
                })
              }}
            >
              Mostrar planos
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default CellPhonePlan
